import classNames from 'classnames';
import React from 'react';

import Header from '../components/header';
import Layout from '../components/layout';
import Link from '../components/link';
import SEO from '../components/SEO';
import { localeLink } from '../helpers';
import { useRegion } from '../hooks/useRegion';
import SectionOpener from '../sections/section-opener/section-opener';
import { resolve } from '../urls';

const pageWrapClasses = classNames({
  'page': true,
  'page--404': true,
});

const NotFoundPage = (props) => {
  const { whatRegion } = useRegion();

  let relevantLink;

  if (props.location.pathname.indexOf('insights') > -1) {
    relevantLink = (
      <Link className="button-icon" to={resolve('insights:home')}>
        Click here to return to Insights.
      </Link>
    );
  } else if (props.location.pathname.indexOf('case-studies') > -1) {
    relevantLink = (
      <Link className="button-icon" to={resolve('cases:list', { locale: whatRegion })}>
        Click here to return to Case Studies.
      </Link>
    );
  } else if (props.location.pathname.indexOf('people') > -1) {
    relevantLink = (
      <Link className="button-icon" to={resolve('people:list')}>
        Click here to return to People.
      </Link>
    );
  } else if (props.location.pathname.indexOf('services') > -1) {
    relevantLink = (
      <Link className="button-icon" to={resolve('services:list', { locale: whatRegion })}>
        Click here to return to Services.
      </Link>
    );
  }

  return (
    <div className={pageWrapClasses}>
      <Layout>
        <SEO title="Page Not Found" description="The page you are looking for can't be found" />
        <SectionOpener openerType="404">
          <Header heading="The page you are looking for can't be found" label="404 - Not Found" />
          <div className="error-link">{relevantLink}</div>
          <div className="error-link">
            <Link className="button-icon" to={resolve('index', { locale: whatRegion })}>
              Click here to return Home.
            </Link>
          </div>
        </SectionOpener>
      </Layout>
    </div>
  );
};

export default NotFoundPage;
